import React from 'react'
import { SEO, Layout } from '../components'
import PageHeader from '../components/page-header'
import { StaticQuery, graphql, Link } from 'gatsby'
import styles from '../components/services.module.css'
import { Services } from '../components'
function Hizmetlerimiz() {
  return (
    <StaticQuery
      query={graphql`
         {
          services: allMarkdownRemark(
            filter: { fileAbsolutePath: { regex: "/data/hizmetlerimiz/" }, frontmatter: {type: {in: "services"}}},
            sort: { fields: frontmatter___sort }
            limit: 6
          ) {
            edges {
              node {
                id
                fields{
                slug
                }
                frontmatter {
                  url
                  content
                  summary
                  title
                  icon
                  color
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        return (
          <Layout>
            <SEO title="Hizmetlerimiz" description="Hizmetlerimiz Timurlar Çatalca sigorta olarak aşağıdaki sigorta branşları dahil olmak üzere 7/24 hizmet kapsamında tüm sigorta ürünlerimiz ile hizmetinizdeyiz." />
            <PageHeader>
              <h1>Hizmetlerimiz</h1>
              <p>
                  Hizmetlerimiz Timurlar Çatalca sigorta olarak aşağıdaki sigorta branşları dahil olmak üzere 7/24 hizmet kapsamında tüm sigorta ürünlerimiz ile hizmetinizdeyiz.

              </p>
              <Link className="btn btn-banner" to="/teklif-al">
                Hemen Teklif Al
              </Link>
            </PageHeader>
            <div className={[styles.pageContent].join(' ')}>
              <Services subpage={true} />
            </div>
          </Layout>
        )
      }}
    />
  )
}
export default Hizmetlerimiz
